import Login from './features/Login'

import './App.css';

function App() {
  return (
    <Login />
  );
}

export default App;
