export function postInvitationRequest(data) {
    const url = 'https://hooks.slack.com/services/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';

    return fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            console.log('Success:', res);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}
