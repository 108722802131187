import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';

import { postInvitationRequest } from "./services";

import {
  EMAIL_REGEX, EXCLUSIVE_RESIDENT_PERKS,
  INVITATION_SUCCESS_MESSAGE,
  REQUEST_AN_INVITATION,
  REQUEST_INVITATION
} from '../../common/constants';

import logo from '../../images/logo.png';
import GreenTickIcon from '../../images/green-tick.png';

import './style.scss';

const Login = (props) => {
  const [requestInvitationEmail, setRequestInvitationEmail] = useState('');
  const [invitationSubmitSuccess, setInvitationSubmitSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  return (
    <div className="login-wrapper">
      <div className="login-left-part" id="login-left-part">
        <div className="login-logo-container">
          <a href="/">
            <img
              alt="logo"
              src={logo}
              className="login-logo"
            />
          </a>
        </div>
        <div className="login-left-part-bottom">
          Resident benefits at your fingertips.
        </div>
      </div>
      <div className="login-right-part">
        <div className="login-right-part-logo-mobile">
          <img
            alt="logo"
            src={logo}
            className="login-logo"
          />
        </div>
        <Container className="login-container">
          <div className="login-heading">
            <h2>{EXCLUSIVE_RESIDENT_PERKS}</h2>
          </div>
          {!invitationSubmitSuccess &&
          <Form className="request-invitation-wrapper">
            <Form.Group controlId="formInvitationEmail">
              <Form.Label>
              <span className="request-invitation-wrapper-demo">
                {REQUEST_AN_INVITATION}
              </span>
              </Form.Label>
              <Form.Control
                  className="request-email"
                  required
                  type="email"
                  name="requestEmail"
                  placeholder="Enter Email"
                  value={requestInvitationEmail}
                  onChange={(event) => {
                    setRequestInvitationEmail(event.target.value);
                    setIsValidEmail(validateEmail(event));
                  }}
              />
            </Form.Group>
            <Button
                className={`request-invt-button-${
                  isValidEmail ? 'active' : 'inactive'
                }`}
                block
                onClick={(event) => {
                  event.preventDefault();
                  setInvitationSubmitSuccess(onSubmit(requestInvitationEmail))
                }}
                disabled={!isValidEmail}
            >
              {REQUEST_INVITATION}
            </Button>
          </Form>}
          {invitationSubmitSuccess && (
              <div className="request-submit-status">
                <div className="request-submit-status-success">
                  <img className="green-tick-icon" src={GreenTickIcon} alt="green-tick-icon" />
                  <span className="green-tick-icon-text">{INVITATION_SUCCESS_MESSAGE}</span>
                </div>
                <div className="request-submit-status-text">
                  We will send your invitation to {requestInvitationEmail}
                </div>
              </div>
          )}
        </Container>
      </div>
    </div>
  )
}

const validateEmail = event => {
  event.preventDefault();
  const value = event.currentTarget.value.trim();
  const isEmailValid = EMAIL_REGEX.test(value);
  return(isEmailValid);
}

const onSubmit = email => {
  postInvitationRequest({'text': `residentperk.com => demo request from ${email}`})
  return true;
}

export default Login;
